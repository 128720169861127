<template>
  <section v-if="error" class="hero is-fullheight is-primary">
    <div class="hero-body is-justify-content-center">
      <div class="columns is-vcentered">
        <div class="column has-text-centered">
          <p v-if="error.code" class="title">{{ error.code }}</p>
          <p class="subtitle">
            {{ error.message }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ErrorMessage',
  props: ['error']
}
</script>
